import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/loader/Loadable';
//import Prueba from '../views/apps/pruebas/Prueba'; // Importa el componente Prueba.js

/****Layouts*****/

const FullLayout = Loadable(lazy(() => import('../layouts/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/BlankLayout')));
/***** Pages ****/

const Modern = Loadable(lazy(() => import('../views/dashboards/Modern')));
/***** Apps ****/

//const Contacts = Loadable(lazy(() => import('../views/apps/contacts/Contacts')));

/***** PRUEBAS DOUGLAS ****/
const Pruebas2 = Loadable(lazy(() => import('../views/apps/pruebas/Prueba')));
const Depa = Loadable(lazy(() => import('../views/departamento/Departamento')));
const Muni = Loadable(lazy(() => import('../views/municipios/Municipio')));
const Cole = Loadable(lazy(() => import('../views/colegios/Colegio')));
const Sede = Loadable(lazy(() => import('../views/sedes/Sedes')));
const Codi = Loadable(lazy(() => import('../views/codigo/Codigo')));
const Perfil = Loadable(lazy(() => import('../views/perfil/Perfil')));
const Rol = Loadable(lazy(() => import('../views/rol/Rol')));
const Home = Loadable(lazy(() => import('../views/home/Home')));
const Res = Loadable(lazy(() => import('../views/resultados/Resultados')));
const Test= Loadable(lazy(() => import('../views/test/Test')));
const Encuesta = Loadable(lazy(() => import('../views/encuesta/Encuesta')));
const Pregunta = Loadable(lazy(() => import('../views/pregunta/Pregunta')));
const Carrera = Loadable(lazy(() => import('../views/carrera/Carrera')));
const Facultad = Loadable(lazy(() => import('../views/facultad/Facultad')));
const Sala = Loadable(lazy(() => import('../views/salas/Salas')));

const Espe = Loadable(lazy(() => import('../views/especifico/Especifico')));
const Credi = Loadable(lazy(() => import('../views/credito/Credito')));
const Recu = Loadable(lazy(() => import('../views/recuperacion/Recu')));
const Cam = Loadable(lazy(() => import('../views/cambiarc2/Cambio')));

/* Reportes  */
const RCole = Loadable(lazy(() => import('../views/reportes/Rcolegio')));
const RCarrera = Loadable(lazy(() => import('../views/reportes/Rcarreras')));
const RvisitasColegio = Loadable(lazy(() => import('../views/reportes/RvisitasColegio')));
const RCues = Loadable(lazy(() => import('../views/reportes/Rcuestionarios')));
const RVisita= Loadable(lazy(() => import('../views/reportes/Rvisita')));

/* Personalizar  */
const Personalizar = Loadable(lazy(() => import('../views/personalizar/Personalizar')));

/***** Auth Pages ****/
const Error = Loadable(lazy(() => import('../views/auth/Error')));
const RegisterFormik = Loadable(lazy(() => import('../views/auth/RegisterFormik')));
const LoginFormik = Loadable(lazy(() => import('../views/auth/LoginFormik')));
const Maintanance = Loadable(lazy(() => import('../views/auth/Maintanance')));
const LockScreen = Loadable(lazy(() => import('../views/auth/LockScreen')));
const RecoverPassword = Loadable(lazy(() => import('../views/auth/RecoverPassword')));

/*****Routes******/

const ThemeRoutes = [
  {
    path: '/',
    element: <FullLayout />,
    children: [
      { path: '/', name: 'Home', element: <Navigate to="/dashboard" /> },
      /* { path: '/dashboards/modern', name: 'Modern', exact: true, element: <Modern /> },
      { path: '/apps/contacts', name: 'contacts', exact: true, element: <Contacts /> }, */
      {
        path: '/dashboard',
        name: 'Modern',
        exact: true,
        element: <Modern />,
      },
      /***** PRUEBAS DOUGLAS ****/
      {
        path: '/apps/pruebas', // Puedes personalizar la URL según tus necesidades
        name: 'Pruebas2', // Puedes personalizar el nombre de la ruta
        exact: true, // Opcional: si deseas que coincida exactamente con la URL
        element: <Pruebas2 /> // Renderiza el componente Prueba.js
      },
      {
        path: '/usuarios/perfil', // Puedes personalizar la URL según tus necesidades
        name: 'Perfil', // Puedes personalizar el nombre de la ruta
        exact: true, // Opcional: si deseas que coincida exactamente con la URL
        element: <Perfil/> // Renderiza el componente Prueba.js
      },
      {
        path: '/usuarios/rol', // Puedes personalizar la URL según tus necesidades MIENTRAS
        name: 'Rol', // Puedes personalizar el nombre de la ruta
        exact: true, // Opcional: si deseas que coincida exactamente con la URL
        element: <Rol/> // Renderiza el componente Prueba.js
      },
      {
        path: 'ubicacion/departamentos', // Puedes personalizar la URL según tus necesidades
        name: 'Depa', // Puedes personalizar el nombre de la ruta
        exact: true, // Opcional: si deseas que coincida exactamente con la URL
        element: <Depa/> // Renderiza el componente Prueba.js
      },
      {
        path: 'ubicacion/municipios', // Puedes personalizar la URL según tus necesidades
        name: 'Muni', // Puedes personalizar el nombre de la ruta
        exact: true, // Opcional: si deseas que coincida exactamente con la URL
        element: <Muni/> // Renderiza el componente Prueba.js
      },
      {
        path: '/visitas/colegio', // Puedes personalizar la URL según tus necesidades
        name: 'Cole', // Puedes personalizar el nombre de la ruta
        exact: true, // Opcional: si deseas que coincida exactamente con la URL
        element: <Cole/> // Renderiza el componente Prueba.js
      },
      {
        path: '/visitas/codigo', // Puedes personalizar la URL según tus necesidades
        name: 'Codi', // Puedes personalizar el nombre de la ruta
        exact: true, // Opcional: si deseas que coincida exactamente con la URL
        element: <Codi/> // Renderiza el componente Prueba.js
      },
      {
        path: '/visitas/sala', // Puedes personalizar la URL según tus necesidades
        name: 'Sala', // Puedes personalizar el nombre de la ruta
        exact: true, // Opcional: si deseas que coincida exactamente con la URL
        element: <Sala/> // Renderiza el componente Prueba.js
      },
      {
        path: 'universidad/sedes', // Puedes personalizar la URL según tus necesidades MIENTRAS
        name: 'Sede', // Puedes personalizar el nombre de la ruta
        exact: true, // Opcional: si deseas que coincida exactamente con la URL
        element: <Sede/> // Renderiza el componente Prueba.js
      },
      {
        path: 'visitas/cuestionario', // Puedes personalizar la URL según tus necesidades MIENTRAS
        name: 'Encuesta', // Puedes personalizar el nombre de la ruta
        exact: true, // Opcional: si deseas que coincida exactamente con la URL
        element: <Encuesta/> // Renderiza el componente Prueba.js
      },
      {
        path: '/preguntas', // Puedes personalizar la URL según tus necesidades MIENTRAS
        name: 'Pregunta', // Puedes personalizar el nombre de la ruta
        exact: true, // Opcional: si deseas que coincida exactamente con la URL
        element: <Pregunta/> // Renderiza el componente Prueba.js
      },
      {
        path: 'universidad/carreras', // Puedes personalizar la URL según tus necesidades MIENTRAS
        name: 'Carrera', // Puedes personalizar el nombre de la ruta
        exact: true, // Opcional: si deseas que coincida exactamente con la URL
        element: <Carrera/> // Renderiza el componente Prueba.js
      },
      {
        path: 'universidad/facultades', // Puedes personalizar la URL según tus necesidades MIENTRAS
        name: 'Facultad', // Puedes personalizar el nombre de la ruta
        exact: true, // Opcional: si deseas que coincida exactamente con la URL
        element: <Facultad/> // Renderiza el componente Prueba.js
      },
      {
        path: '/usuarios/changepassword', // Puedes personalizar la URL según tus necesidades MIENTRAS
        name: 'Recuperacion', // Puedes personalizar el nombre de la ruta
        exact: true, // Opcional: si deseas que coincida exactamente con la URL
        element: <Recu/> // Renderiza el componente Prueba.js
      },
      {
        path: 'usuarios/recoverpassword', // Puedes personalizar la URL según tus necesidades MIENTRAS
        name: 'Cambio', // Puedes personalizar el nombre de la ruta
        exact: true, // Opcional: si deseas que coincida exactamente con la URL
        element: <Cam/> // Renderiza el componente Prueba.js
      },


      {
        path: '/reportes/colegios', // Puedes personalizar la URL según tus necesidades MIENTRAS
        name: 'Rcolegios', // Puedes personalizar el nombre de la ruta
        exact: true, // Opcional: si deseas que coincida exactamente con la URL
        element: <RCole/> // Renderiza el componente Prueba.js
      },

      {
        path: 'reportes/carreras', // Puedes personalizar la URL según tus necesidades MIENTRAS
        name: 'Rcarreras', // Puedes personalizar el nombre de la ruta
        exact: true, // Opcional: si deseas que coincida exactamente con la URL
        element: <RCarrera/> // Renderiza el componente Prueba.js
      },
      {
        path: '/reportes/vistasColegio', // Puedes personalizar la URL según tus necesidades MIENTRAS
        name: 'ReportesVisitas', // Puedes personalizar el nombre de la ruta
        exact: true, // Opcional: si deseas que coincida exactamente con la URL
        element: <RvisitasColegio/> // Renderiza el componente Prueba.js
      },
      {
        path: 'reportes/cuestionarios', // Puedes personalizar la URL según tus necesidades MIENTRAS
        name: 'Rcuestionarios', // Puedes personalizar el nombre de la ruta
        exact: true, // Opcional: si deseas que coincida exactamente con la URL
        element: <RCues/> // Renderiza el componente Prueba.js
      },
      {
        path: '/reportes/visita', // Puedes personalizar la URL según tus necesidades MIENTRAS
        name: 'ReportesVisita', // Puedes personalizar el nombre de la ruta
        exact: true, // Opcional: si deseas que coincida exactamente con la URL
        element: <RVisita/> // Renderiza el componente Prueba.js
      },
      {
        path: '/editarinicio', // Puedes personalizar la URL según tus necesidades MIENTRAS
        name: 'Personalizar', // Puedes personalizar el nombre de la ruta
        exact: true, // Opcional: si deseas que coincida exactamente con la URL
        element: <Personalizar/> // Renderiza el componente Prueba.js
      },

    ],
  },
  {
    path: '/',
    element: <BlankLayout />, // Esto es para que no muestre sidebar ni topbar
    children: [
      {
        path: 'inicio', // Puedes personalizar la URL según tus necesidades
        name: 'Inicio', // Puedes personalizar el nombre de la ruta
        exact: true, // Opcional: si deseas que coincida exactamente con la URL
        element: <Home /> // Renderiza el componente Home.js
      },
      
    ],
  },
  {
    path: '/',
    element: <BlankLayout />,
    children: [
      {
        path: 'resultadosGenerales', // Puedes personalizar la URL según tus necesidades
        name: 'Resultados', // Puedes personalizar el nombre de la ruta
        exact: true, // Opcional: si deseas que coincida exactamente con la URL
        element: <Res /> // Renderiza el componente Home.js
      },
    ],
  },
  {
    path: '/',
    element: <BlankLayout />, // Esto es para que no muestre sidebar ni topbar
    children: [
      {
        path: 'test', // Puedes personalizar la URL según tus necesidades
        name: 'Test', // Puedes personalizar el nombre de la ruta
        exact: true, // Opcional: si deseas que coincida exactamente con la URL
        element: <Test /> // Renderiza el componente Home.js
      },
      
    ],
  },
  {
    path: '/',
    element: <BlankLayout />, // Esto es para que no muestre sidebar ni topbar
    children: [
      {
        path: 'resultadosEspecificos', // Puedes personalizar la URL según tus necesidades
        name: 'Especifico', // Puedes personalizar el nombre de la ruta
        exact: true, // Opcional: si deseas que coincida exactamente con la URL
        element: <Espe /> // Renderiza el componente Home.js
      },
      
    ],
  },

  {
    path: '/',
    element: <BlankLayout />, // Esto es para que no muestre sidebar ni topbar
    children: [
      {
        path: 'credito', // Puedes personalizar la URL según tus necesidades
        name: 'Credito', // Puedes personalizar el nombre de la ruta
        exact: true, // Opcional: si deseas que coincida exactamente con la URL
        element: <Credi /> // Renderiza el componente Home.js
      },
      
    ],
  },
  
  {
    path: '/auth',
    element: <BlankLayout />,
    children: [
      { path: '401', element: <Error /> },
      { path: '*', element: <Navigate to="/auth/401" /> },
      { path: 'registro', element: <RegisterFormik /> },
      { path: 'login', element: <LoginFormik /> },
      { path: 'maintanance', element: <Maintanance /> },
      { path: 'lockscreen', element: <LockScreen /> },
      { path: 'recoverpwd', element: <RecoverPassword /> },


    ],
  },
];

export default ThemeRoutes;
