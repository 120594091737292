import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';  // Importa PropTypes

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // Contexto True
  const login = () => {
    setIsLoggedIn(true);
  };

  // Contexto False
  const logout = () => {
    setIsLoggedIn(false);
  };

  // Se retornan los cambios
  return (
    <AuthContext.Provider value={{ isLoggedIn, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  // Validación de 'children'
  children: PropTypes.node.isRequired,
};

export const useAuth = () => {
  return useContext(AuthContext);
};
